
import call from "../assets/images/about/call.svg"
import about01 from "../assets/images/about/main/about-01.png"
import about02 from "../assets/images/about/main/about-02.png"

export default function AboutSection() {
    return(<>
    <>
  {/* rts about us section start */}
  <div className="rts-about-area rts-section-gap bg-about-sm-shape">
    <div className="container">
      <div className="row g-5 align-items-center">
        {/* about left */}
        <div className="col-lg-6 col-md-12 col-sm-12 col-12 order-lg-1 order-md-2 order-sm-2 order-2 mt_md--50 mt_sm--50">
          <div className="rts-title-area">
            <p className="pre-title">Al-Khair Baitul Maal Urban Co-operative Credit Society Limited</p>
            <h2 className="title">About us</h2>
          </div>
          <div className="about-inner">
            <p className="disc">
            Al-Khair Baitul Maal Urban Co-operative Credit Society Limited, established in 2002, is a voluntary, non-political, and non-profit social service organization based in Aurangabad, Maharashtra. We are registered under the Maharashtra State Co-operative Society Act of 1960 and are dedicated to promoting interest-free financial services in our community.

Our primary aim is to facilitate and develop interest-free micro-finance institutions (IFMFI) that empower individuals through ethical and inclusive financial solutions. By offering need-based research and services, we strive to help those who lack access to conventional banking systems, ensuring they can take control of their financial futures without the burden of interest.
            </p>
            {/* start about success area */}
            <div className="row about-success-wrapper">
              {/* left wrapper start */}
              <div className="single">
                  <i className="far fa-check" />
                  <p className="details">Promote Interest-Free Financial Services</p>
                </div>
              <div className="col-lg-6 col-md-6">
                
                <div className="single">
                  <i className="far fa-check" />
                  <p className="details">Support Financial Inclusion</p>
                </div>
                <div className="single">
                  <i className="far fa-check" />
                  <p className="details">Encourage Savings and Investment</p>
                </div>
              </div>
              {/* left wrapper end */}
              <div className="col-lg-6 col-md-6">
                <div className="single">
                  <i className="far fa-check" />
                  <p className="details">Conduct Need-Based Research</p>
                </div>
                <div className="single">
                  <i className="far fa-check" />
                  <p className="details">Foster Community Empowerment</p>
                </div>
               
              </div>
            </div>
            {/* start about success area */}
            {/* about founder & get in touch start */}
            <div className="row about-founder-wrapper align-items-center mt--40">
              {/* left area start */}
              {/* <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                <div className="author-inner">
                  <a className="thumbnail" href="#">
                    <img
                      src={sm01}
                      alt="finbiz_founder"
                    />
                  </a>
                  <div className="founder-details">
                    <a href="./">
                      <h6 className="title">Adrew David</h6>
                    </a>
                    <span>CEO &amp; Founder</span>
                  </div>
                </div>
              </div> */}
              {/* left area end */}
              {/* right founder area */}
              <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt_sm--20">
             
              </div>
              {/* left founder area */}
            </div>
            {/* about founder & get in touch end */}
          </div>
        </div>
        {/* about right */}
        {/* about-right Start*/}
        <div className="col-lg-6 col-md-12 col-sm-12 col-12 order-lg-2 order-md-1 order-sm-1 order-1">
          <div className="about-one-thumbnail">
            <img
              src={about01}
              alt="about-finbiz"
            />
            <img
              className="small-img"
              src={about02}
              alt="finbiz-small"
            />
            <div className="experience">
              <div className="left single">
                <h2 className="title">20+</h2>
                <p className="time">Years</p>
              </div>
              <div className="right single">
                <p className="disc">Of experience </p>
              </div>
            </div>
          </div>
        </div>
        {/* about-right end */}
      </div>
    </div>
  </div>
  {/* rts about us section end */}
</>

    </>)
}